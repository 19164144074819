<template>
  <div id="">
    <Navbar></Navbar>
    <v-layout row>
      <v-flex v-if="confirm==false" xs12 pa-5 class="text-xs-center">
        Are you sure you want to opt out of our amazing recommendation emails?
        <br>
        <v-btn :disabled="working" color="error" @click="unsubscribe">Unsubscribe</v-btn>
      </v-flex>
      <v-flex v-else xs12 pa-5 class="text-xs-center">
        You have been successfully unsubscribed.
        <br>
        You will no longer receive promotional emails from us.
        <br>
        <v-btn color="primary" :disabled="working" @click="subscribe">Subscribe</v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from 'axios'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'

export default {
  name: "",
  data: () => ({
    confirm:false,
    working:false

  }),
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  methods: {
    unsubscribe() {
      this.working=true
      axios.patch('users/'+this.user.id+'.json?auth='+this.$store.state.auth.idToken,{optOut:true})
      .then(res=>{
        this.confirm=true
        this.working=false
      })
      .catch(err=>{
        console.log(err);
        alert('something happened, please refresh and try again')
        this.working=false
      })
    },
    subscribe() {
      this.working=true
      axios.patch('users/'+this.user.id+'.json?auth='+this.$store.state.auth.idToken,{optOut:false})
      .then(res=>{
        this.confirm=false
        this.working=false
      })
      .catch(err=>{
        console.log(err);
        alert('something happened, please refresh and try again')
        this.working=false
      })
    }
  },
  created() {
    //do something after creating vue instance
    if (this.user.optOut==true){
      this.confirm=true
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
